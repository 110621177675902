// consumer_journey_getEvectionDetailV2
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.haolvtrip.com/doc.html#/yinzhilv-tmc/%E8%A1%8C%E7%A8%8B%E9%A2%84%E8%AE%A2/journeyTypeListUsingPOST
const consumer_journey_getEvectionDetailV2 = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/journey/getEvectionDetailV2',
    data: pParameter,
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_journey_getEvectionDetailV2;
