/**
 * 初始化方法
 * init function 初始化方法
 * params obj 入参
 * {
 *     evectionNo: 出差单号
 *     businessType: 业务类型， 1机票 2火车票 3酒店
 *     evectionRequired： 出差单是否必填 0 否 1 是
 *     businessRuleControl:  因公出差规则 1：出差单审批 2：预订审批
 *     journeyId: 行程单id， 没有传0
 * }
 *
 * 推出方法
 * @toEdit 当点击编辑按钮时推出
 * 无出参
 * */
import moment from "moment"
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import TravelPolicyTips from '@/component/travelPolicyTips/import_latest_version_module'
import CostAttributionList from '@/page/admin/travel-book/reserve/component/costAttributionList/index.vue'
import consumer_web_policyConfig_myTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_myTravelPolicy' // 我的差标
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_journey_getEvectionDetailV2 from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2' // 获取出差信息
import consumer_journey_payCenterDetails from '@/lib/data-service/haolv-default/consumer_journey_payCenterDetails' // 支付中心
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0'
import consumer_web_policyConfig_getUserTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getUserTravelPolicy";
export default {
    data() {
        return {
            policyControlText: '差标根据出行人中最严差标为准',
            loading: true,
            businessType: 0,   // 业务类型：1.机票，2.火车票，3.酒店
            evectionNo: '',
            journeyId: 0,
            hotelCityId: 0,
            evectionDetailType: '',   // '001': 因公出差带出差单, '002': 因公出差不带出差单, '101': 因私出差
            evectionRequired: 0,
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批

            userInfo: {},   // 个人信息
            evectionInfo: {},
            travelUserList: [],
            popoverLoading: false,
            myTravelPolicy: {},

            travelPolicyTips: [],
            trainPolicyTips: {},

            flightList: [],
            trainList: [],
            hotelList: [],
        }
    },
    components: {
        TravelPolicyTips,
        CostAttributionList
    },
    mounted() {
        let evectionParams = {
            vm: this
        }
        const evectionQuery = getEvectionAllType(evectionParams)
        this.evectionDetailType = evectionQuery.evectionDetailType
    },
    filters: {
        filterMontyAndDay(val) {
            return moment(val).format('MM-DD')
        }
    },
    computed: {
        showStandard() {
            const evectionInfo = this.evectionInfo
            if (JSON.stringify(evectionInfo) === '{}') {
                return false
            }
            const evectionType = evectionInfo.evectionType
            let workTravelType = ''
            if (evectionType === 2) {
                workTravelType = evectionInfo.journeys.length > 0 ? 1 : 2  // 因公出差的类型， 1是有选出差单，2是没有
            }
            const hasTravelUser = evectionInfo.travellerList.length > 0 ? true : false
            let isShowStandard = true
            if (evectionType === 1) {
                isShowStandard = false
            }
            // if (evectionType === 2 && workTravelType === 2 && !hasTravelUser) {
            //     isShowStandard = false
            // }
            return  isShowStandard
        }
    },
    methods: {
        async init(params) {
            this.loading = true
            this.evectionNo = params.evectionNo
            this.businessType = params.businessType
            this.evectionRequired = params.evectionRequired
            this.businessRuleControl = params.businessRuleControl
            this.journeyId = params.journeyId
            if (this.businessType === 3) {
                this.hotelCityId = params.hotelCityId
            }
            if (this.evectionDetailType === '101') {
                return
            }
            await this._getUserInfo()
            Promise.all([this._getEvectionInfo(), this._getPayCenterDetails()]).finally(() =>{
                this.loading = false
                this._getTravelPolicyTips()
            })
        },
        _toEdit() {
            this.$emit('toEdit')
        },
        // 个人差标
        _showTravelPolicyPopover(val) {
            this.popoverLoading = true
            let params = {}
            params = {
                user: {
                    id: val.userId
                }
            }
            if (this.businessType === 3) {
                params = {
                    hotelCityId: this.hotelCityId,
                    ...params
                }
            }
            consumer_web_policyConfig_myTravelPolicy(params).then(res => {
                this.myTravelPolicy = {
                    businessType: this.businessType,
                    ...res.datas
                }
            }).catch(() => {

            }).finally(() => {
                this.popoverLoading = false
            })
        },
        // 预订中心
        _goReservePage() {
            this.$router.push({
                name: 'admin-payment',
                query: {
                    evectionNo: this.evectionNo,
                }
            })
        },
        // 获取个人信息
        async _getUserInfo() {
            const [err, res] = await awaitWrap(get_user_info())
            if (err) {
                return
            }
            this.userInfo = res.datas
        },
        // 获取出差信息
        async _getEvectionInfo() {
            const [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({
                evectionNo: this.evectionNo,
                pageType: 1,
                personType: 2
            }))
            if (err) {
                return
            }
            this.evectionInfo = res.datas
            this.travelUserList = this.evectionInfo.travellerList
        },
        // 获取整体的差标提示
        async _getTravelPolicyTips() {
            let travelUserIdList = [];
            travelUserIdList = this.travelUserList.map((val)=>val.userId);
            let params = {}
            params = {
                businessType: this.businessType,
                evectionNo: this.evectionNo,
                userIds: travelUserIdList,
                applyJourneyId: this.journeyId || '',
            }
            if (this.businessType === 3) {
                params = {
                    hotelCityId: this.hotelCityId,
                    ...params
                }
            }
            const [err, res] = await awaitWrap(consumer_web_policyConfig_getUserTravelPolicy(params));
            if (err) {
                return
            }
            this.policyControlText = res.datas.policyControlText;
            let travelPolicyTips = []
            let newTravelPolicyTips = []
            if (this.businessType === 1) {
                travelPolicyTips = res.datas.flightMsg
            }
            if (this.businessType === 3) {
                travelPolicyTips = res.datas.hotelMsg
            }
            travelPolicyTips.forEach(value => {
                newTravelPolicyTips.push(value.replace(/：/g, ':').split(':'))
            })
            this.travelPolicyTips = newTravelPolicyTips
            this.trainPolicyTips = res.datas.trainsMsg
        },
        // 支付中心
        async _getPayCenterDetails() {
            const [err, res] = await awaitWrap(consumer_journey_payCenterDetails({
                evectionNo: this.evectionNo
            }))
            if (err) {
                return
            }
            if (res.datas && Object.keys(res.datas).length) {
                // 获取机票行程
                if (res.datas.flightDetail && Object.keys(res.datas.flightDetail).length) {
                    this.flightList = res.datas.flightDetail.orderList.length ? res.datas.flightDetail.orderList : []
                }
                // 获取火车票行程
                if (res.datas.trainDetail && Object.keys(res.datas.trainDetail).length) {
                    this.trainList = res.datas.trainDetail.payOrderList.length ? res.datas.trainDetail.payOrderList : []
                }
                // 获取酒店行程
                if (res.datas.hotelDetail && Object.keys(res.datas.hotelDetail).length) {
                    this.hotelList = res.datas.hotelDetail.unPaidHotelList.length ? res.datas.hotelDetail.unPaidHotelList : []
                }
            }
        },
    }
}
